.json-formatter {
    font-family: monospace; /* Use a monospaced font for consistent formatting */
    font-size: 14px; /* Adjust font size as needed */
    background-color: #f5f5f5; /* Light background for better readability */
    padding: 10px; /* Add some padding for visual separation */
    border-radius: 3px; /* Rounded corners for a cleaner look */
    overflow-x: auto; /* Enable horizontal scrolling for long JSON data */
  }

  .json-formatter key {
    color: #900; /* Darker color for keys */
    font-weight: bold;
  }
  
  .json-formatter string {
    color: #080; /* Green for strings */
  }
  