

:root {
  font-family: 'Montserrat', sans-serif;
}
.table-row {
  border: 1px solid #ddd;
  text-align: center;
}
.column-border {
  border: 1px solid #ddd;
  text-align: center;
}
th{
  background: #00678A!important;
  color: #ddd!important;
  text-align: center!important;
  font-size: 18px!important;
  font-weight: 700!important;
}
.ant-table-thead > tr > td{
  background: #00678A!important;
}